<template>
  <v-dialog persistent width="800px" scrollable v-model="bActive">
    <v-card class="card-dialog-pay-order">
      <v-btn
        @click="close"
        class="btn-close-dialog-pay-order"
        dark
        fab
        small
        icon
        color="black"
        rounded
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <p class="txt-title-dialog-pay-order">Añadir pago</p>
      </v-card-title>

      <v-card-subtitle>
        <!-- #region sección de cantidad actual -->
        <div class="content-current-amount-all">
          <div class="content-txt-current-amount">
            <p class="txt-current-amount">Total pagado</p>
          </div>
          <div class="content-center-pointer" />
          <div class="content-txt-total">
            <p class="txt-total">${{ getFormatMoney(dTotalPayments) }} MXN</p>
          </div>
        </div>
        <!-- #endregion sección de cantidad actual -->
      </v-card-subtitle>

      <v-card-text>
        <!-- #region formulario de registro de pago -->
        <v-row>
          <!-- #region campo de cantidad a pagar -->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div class="content-txt-result-global mt-4">
              <p class="txt-title-dialog-pay-order-global">Cantidad a pagar</p>
              <v-text-filed-currency
                v-model="dAmountPay"
                :options="oOptionsCurrency"
                outline
                flat
                sClass=""
                placeholder="00.00"
                hide-details
                dense
              />
            </div>
          </v-col>
          <!-- #endregion campo de cantidad a pagar -->

          <!-- #region campo de rango de fechas -->
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div class="content-txt-result-global mt-4">
              <p class="txt-title-dialog-pay-order-global">Fecha</p>
              <v-menu
                ref="menu"
                v-model="bMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="sDates"
                    outline
                    flat
                    hide-details
                    chips
                    clearable
                    dense
                    placeholder="Selecciona fecha..."
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  >
                    <template slot="append">
                      <v-icon @click="bMenu = true">mdi-calendar-blank</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="sDates"
                  :max="sMaxDate"
                  no-title
                  color="primary"
                  locale="es"
                >
                  <v-spacer></v-spacer>
                  <!-- <v-btn text color="primary" @click="bMenu = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    :disabled="!sDates"
                    text
                    color="primary"
                    @click="$refs.menu.save(sDates), save(sDates)"
                  >
                    <span :class="sDates ? 'color-yellow-global' : ''"
                      >Guardar</span
                    >
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <!-- #endregion campo de rango de fechas -->

          <!-- #region campo de descripcion de pago -->
          <v-col cols="12">
            <div class="content-txt-result-global mt-4">
              <p class="txt-title-dialog-pay-order-global">
                Descripción de pago
              </p>
              <v-textarea
                v-model="sNote"
                rows="3"
                class="textarea-global mt-0 pt-0"
                placeholder="Descripción de pago"
                :persistent-placeholder="true"
              ></v-textarea>
            </div>
          </v-col>
          <!-- #endregion campo de descripcion de pago -->

          <!-- #region checkbox de confirmación -->
          <v-col cols="12">
            <div class="content-double-check-partial-payments-all">
              <v-checkbox
                v-model="bCheckPartialPaymenys"
                class="mt-0"
                color="#FFCF2E"
                hide-details
              >
                <template v-slot:label>
                  <span class="txt-double-check-partial-payments">
                    He revisado la cantidad ingresada y asumo la responsibilidad
                    de registrarla en el total de la orden de compra.
                  </span>
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <!-- #endregion checkbox de confirmación -->
        </v-row>
        <!-- #endregion formulario de registro de pago -->
      </v-card-text>

      <v-card-actions>
        <div class="card-action-dialog-pay-order">
          <v-btn @click="close" large outlined color="black">
            <p class="buttonOption">Cancelar</p>
          </v-btn>
          <v-btn
            @click="payItem"
            :loading="bLoadingBtnPay"
            :disabled="!bValidateForm"
            large
            depressed
            color="primary"
          >
            <p style="color: black" class="buttonOption">Añadir</p>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    item: Object,
    bActive: Boolean,
    dTotalPayments: {
      type: Number,
      default:0
    },
    sOCId:String
  },
  data() {
    return {
      bLoading: false,
      sPayId: "",
      aPay: [
        {
          sId: "1",
          sName: "Pagar $0.00 MXN (Pago total)",
        },
        {
          sId: "2",
          sName: "Otro importe (Pago parcial)",
        },
      ],
      dAmountPay: null,
      oOptionsCurrency: {
        locale: "en-US",
        prefix: "$",
        suffix: "MXN",
        length: 11,
        precision: 2,
      },
      bMenu: false,
      aDates: [],
      tStart: "",
      tEnd: "",
      sDates: null,
      sMaxDate: new Date().toISOString().slice(0, 10),
      bAddDates: false,
      sNote: "",
      bCheckPartialPaymenys: false,
      paymentStatus: "b17505a2-c7d4-4bbd-9cad-07dd23092199",
      bLoadingBtnPay: false,
    };
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          var paymentStatus = resp.data.orderStatus;
          // this.paymentStatus = paymentStatus[4].sPurchaseOrderStatusId
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getFormatMoney(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    //, toggle function delete
    close() {
      this.dAmountPay = null;
      this.aDates = [];
      this.tStart = "";
      this.tEnd = "";
      this.sDates = null;
      this.bAddDates = false;
      this.sNote = "";
      this.bCheckPartialPaymenys = false;
      this.$emit("close");
    },
    payItem() {
      // #region codigo nuevo de pago
      this.bLoadingBtnPay = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };
      const params = {
        dAmount:this.dAmountPay,
        tTransactionDate: this.sDates,
        sComments: this.sNote,
      };
      db.post(
        `${uri}/api/v1/purchase_order/order/${this.sOCId}/payments`,
        params,
        config
      )
        .then((resp) => {
          this.bLoadingBtnPay = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
          this.close();
        })
        .catch((err) => {
          this.bLoadingBtnPay = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
      // #endregion codigo nuevo de pago
    },
    save(aDates) {
      this.$refs.menu.save(aDates);
      if (this.aDates.length > 1) {
        let fecha_inicial = this.aDates[0];
        let fecha_final = this.aDates[1];
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.aDates[1];
          this.aDates = [];
          this.aDates[0] = lastDate;
        } else {
          this.tStart = fecha_inicial;
          this.tEnd = fecha_final;
          this.sDates = this.tStart + " - " + this.tEnd;
          // this.$emit("getDateRange", {
          //   tStart: this.tStart,
          //   tEnd: this.tEnd,
          // });
        }
      }
    },
  },
  computed: {
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    bValidateForm() {
      return (
        this.dAmountPay > 0 &&
        this.sDates !== null &&
        this.bCheckPartialPaymenys
      );
    },
  },
  watch: {
    aDates() {
      if (this.aDates !== null) {
        if (this.aDates.length > 1) {
          this.bAddDates = true;
          if (this.aDates[0] > this.aDates[1]) {
            let fecha_inicial = this.aDates[1];
            this.aDates = [];
            this.aDates.push(fecha_inicial);
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.tStart = null;
        this.tEnd = null;
        // this.$emit("getDateRange", {
        //   tStart: this.tStart,
        //   tEnd: this.tEnd,
        // });
      }
    },
    sDates() {
      if (this.sDates == null) {
        this.aDates = [];
        this.$refs.menu.save([]);
        this.bAddDates = false;
        this.tStart = null;
        this.tEnd = null;
        // this.$emit("getDateRange", {
        //   tStart: this.tStart,
        //   tEnd: this.tEnd,
        // });
      } else {
        this.bMenu = false
      }
    },
    sPayId() {
      if (this.sPayId !== "2") {
        this.dAmountPay = null;
      }
    },
  },
};
</script>

<style scoped>
.card-dialog-pay-order {
  border-radius: 25px !important;
  background: #ffffff;
  box-shadow: 0px 3px 40px #00000019;
}
.btn-close-dialog-pay-order {
  position: absolute;
  right: 5px;
  top: 5px;
}
.txt-title-dialog-pay-order {
  text-align: center !important;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  margin: 20px 0px;
}
.txt-subtitle-dialog-pay-order {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0px !important;
}
/* #region content-current-amount-all */
.content-current-amount-all {
  display: flex;
  margin-top: 10px;
  background-color: #fff1c0;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  min-height: 70px;
}
.content-txt-current-amount {
  /* min-width: 135px; */
}
.txt-current-amount {
  white-space: nowrap;
  color: black;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}
.content-center-pointer {
  width: 100%;
  border-bottom: 1px dashed black;
  margin: 10px 5px 0px 5px;
}
.content-txt-total {
  /* max-width: 175px;
  min-width: 90px; */
}
.txt-total {
  color: black;
  font-size: 16px;
  margin-bottom: 0px;
  white-space: nowrap;
}
/* #endregion content-current-amount-all */

/* #region formulario de registro de pago */
.content-double-check-partial-payments-all {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  min-height: 50px;
}
.txt-double-check-partial-payments {
  font-size: 11px !important;
  color: black;
}

/* #endregion formulario de registro de pago */
.txt-title-dialog-pay-order-global {
  font-family: "Poppins", sans-serif;
  color: #000000;
  font-size: 14px;
  margin-bottom: 0px !important;
}
textarea::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  color: #000000;
  background-color: red !important;
}
.card-action-dialog-pay-order {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
</style>
